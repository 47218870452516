import React from 'react';

import { StyledGapContainer, StyledWrapper } from './gap-container.styles';

export type GapContainerType = {
  children?: JSX.Element | JSX.Element[];
  paddingsize: string;
};

const GapContainer = ({ children, paddingsize }: GapContainerType) => (
  <StyledWrapper>
    <StyledGapContainer padding={paddingsize}>{children}</StyledGapContainer>
  </StyledWrapper>
);

export default GapContainer;
