import React, { ReactElement } from 'react';

import { StyledWrapper } from './underline-container.styles';

export type UnderlineContainerType = {
  children?: ReactElement;
};

const UnderlineContainer = ({ children }: UnderlineContainerType) => (
  <StyledWrapper>{children}</StyledWrapper>
);

export default UnderlineContainer;
