import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import CompanyPdfSections from 'components/organisms/company-pdf-section/company-pdf-section';
import HeadTitleDescription from 'components/organisms/head-title-description';
import CompanyColumns from 'components/molecules/company-columns';
import CompanyTextContainer from 'components/molecules/company-text-container';
import Breadcrumb from 'components/atoms/breadcrumb';
import GapContainer from 'components/atoms/gap-container';
import Typography from 'components/atoms/typography';
import UnderlineContainer from 'components/atoms/underline-container';

export type StockOwnershipType = {
  address: AddressType;
  companyName: CompanyNameType;
  krs: KrsType;
  nip: NipType;
  nominalCapital: NominalCapitalType;
  regon: RegonType;
  sectionHeading: string;
};

type DataProps = {
  stockOwnershipPage: {
    template: {
      stockOwnershipData: {
        bannerSimple: BannerSimpleType;
        generalMeetings: { subTitle: string; meetingsHeading: string; documentsList: any };
        stockOwnership: StockOwnershipType;
      };
    };
  };
};
export type NominalCapitalType = Record<
  'additionalText' | 'nominalCapitalText' | 'nominalCapitalLabel',
  string
>;
export type RegonType = Record<'regonLabel' | 'regonText', string>;
export type NipType = Record<'nipLabel' | 'nipText', string>;
export type KrsType = Record<'krsLabel' | 'krsText', string>;
export type CompanyNameType = Record<'companyNameLabel' | 'companyNameText', string>;
export type BannerSimpleType = Record<'heading' | 'text', string>;
export type AddressType = Record<'addressLabel' | 'addressText', string>;
const CompanyInfoPage = ({
  pageContext,
  data: {
    stockOwnershipPage: {
      template: {
        stockOwnershipData: {
          bannerSimple: { heading, text },
          generalMeetings: { subTitle, meetingsHeading, documentsList },
          stockOwnership: {
            address: { addressLabel, addressText },
            companyName: { companyNameLabel, companyNameText },
            krs: { krsLabel, krsText },
            nip: { nipLabel, nipText },
            nominalCapital: { additionalText, nominalCapitalLabel, nominalCapitalText },
            regon: { regonLabel, regonText },
            sectionHeading,
          },
        },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescription
      isBig
      showDots={false}
      titleTop={heading}
      subtitleTop={text}
      containerWidth="full"
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescription>
    <CompanyPdfSections heading={meetingsHeading} subHeading={subTitle} {...{ documentsList }} />
    <CompanyTextContainer isPaddingTop styledTitle={sectionHeading}>
      <CompanyColumns>
        <GapContainer paddingsize="60px">
          <Typography variant="body3" color="gray60" html={companyNameLabel} />
          <Typography variant="title3" html={companyNameText} />
        </GapContainer>
        <div>
          <Typography variant="body3" color="gray60" html={addressLabel} />
          <Typography variant="title3" html={addressText} />
        </div>
      </CompanyColumns>
    </CompanyTextContainer>
    <UnderlineContainer />
    <CompanyTextContainer>
      <CompanyColumns>
        <div>
          <Typography variant="body3" color="gray60" html={nipLabel} />
          <Typography variant="title3" html={nipText} />
        </div>
        <div>
          <Typography variant="body3" color="gray60" html={regonLabel} />
          <Typography variant="title3" html={regonText} />
        </div>

        <div>
          <Typography variant="body3" color="gray60" html={nominalCapitalLabel} />
          <Typography variant="title3" html={nominalCapitalText} />
          <Typography variant="body2" color="gray40" html={additionalText} />
        </div>
      </CompanyColumns>
    </CompanyTextContainer>
    <UnderlineContainer />
    <CompanyTextContainer isPaddingBottom>
      <CompanyColumns>
        <div>
          <Typography variant="body3" color="gray60" html={krsLabel} />
          <Typography variant="title3" html={krsText} />
        </div>
      </CompanyColumns>
    </CompanyTextContainer>
  </Layout>
);
export const query = graphql`
  query CompanyInfoPage($id: String) {
    stockOwnershipPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_CompanyInfo {
          stockOwnershipData {
            bannerSimple {
              heading
              text
            }
            generalMeetings {
              subTitle
              meetingsHeading: heading
              documentsList {
                downloadLabel
                file {
                  sourceUrl
                  filename
                }
              }
            }
            stockOwnership {
              address {
                addressLabel: label
                addressText: text
              }
              companyName {
                companyNameLabel: label
                companyNameText: text
              }
              krs {
                krsLabel: label
                krsText: text
              }
              nip {
                nipLabel: label
                nipText: text
              }
              nominalCapital {
                additionalText
                nominalCapitalLabel: label
                nominalCapitalText: text
              }
              regon {
                regonLabel: label
                regonText: text
              }
              sectionHeading
            }
          }
        }
      }
    }
  }
`;

export default CompanyInfoPage;
