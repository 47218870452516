import styled from 'styled-components';
import { breakpoint } from 'theme';

import Container from 'components/atoms/container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainerContent = styled(Container)`
  display: flex;
  max-width: ${({ theme }) => theme.wrapper};
  flex-direction: column;
  justify-items: start;
  div {
    margin-bottom: ${({ theme }) => theme.gap.xs};
  }
  ${breakpoint.md} {
    flex-direction: row;
    padding-block: 0;
    column-gap: 264px;
  }
`;
