import React from 'react';

import { StyledContainerContent, StyledWrapper } from './company-columns.styles';

export type CompanyColumnsType = {
  children?: JSX.Element | JSX.Element[];
};

const CompanyColumns = ({ children }: CompanyColumnsType) => (
  <StyledWrapper>
    <StyledContainerContent>{children}</StyledContainerContent>
  </StyledWrapper>
);

export default CompanyColumns;
