import styled from 'styled-components';
import { breakpoint } from 'theme';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledGapContainer = styled.div<{ padding: string }>`
  justify-items: start;
  padding-right: ${(props) => props.padding};
  ${breakpoint.md} {
    padding-block: 0;
  }
`;
